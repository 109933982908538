@import "~bootstrap/dist/css/bootstrap.min.css";
/* @import url("../fonts/stylesheet.css"); */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;700&display=swap");

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  /* --background-start-rgb: 214, 219, 220; */
  /* --background-end-rgb: 255, 255, 255; */

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  overflow: auto;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}
html body {
  font-family: "Noto Sans", sans-serif;
  margin: 0;
  line-height: 1.3;
  background: #f8f8f8;
}

body {
  /* color: rgb(var(--foreground-rgb)); */
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #198754 !important;
}

#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

.image-gallery-image {
  object-fit: contain !important;
}

.image-gallery-slide {
  /* height: 60vh; */
  margin-bottom: 20px;
}

.home-search {
  height: 45px !important;
}

body {
  background: #eee;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body {
  background: #000;
  color: #000;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.sellwithusInfo h4 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}

.sell-top {
  margin-top: 200px;
}

.card {
  height: 280px;
  margin-bottom: 20px;
  cursor: pointer;
}

/* Add border-radius to the card */
.sellToUsBoxMain .card {
  border-radius: 8px;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

/* On hover, change text color to white and background to blue */
.sellToUsBoxMain .card:hover {
  background-color: #043288; /* Blue background */
  color: #ffffff;
  cursor: pointer;
  /* White text */
}

.sellToUsBoxMain .card:hover h6,
.sellToUsBoxMain .card:hover p {
  color: #ffffff; /* Ensure the text inside the card changes to white */
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-blue {
  background-color: #043289;
}

.btn {
  background-color: #043289;
}

.nav-link.active {
  background-color: #043289 !important;
  color: #fff !important;
}

.nav-link.active:hover {
  background-color: #043289 !important;
  color: #fff !important;
}

.custom-dr {
  background-color: transparent !important;
}

ul {
  list-style-type: disc;
  list-style: none;
}

.g-margin {
  margin-top: 200px;
}

.g-margin-2 {
  margin-top: 260px;
}

.g-margin-4 {
  margin-top: 130px;
}

.g-margin-3 {
  margin-top: 120px;
}

.btn-blue {
  color: #fff;
  font-size: 16px;
  padding: 6px 12px;
  line-height: 30px;
  background: #043289;
  border-radius: 0;
  font-weight: lighter;
  font-weight: 600;
  transition: all 0.5s;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  border-radius: 4px;
}

.btn-blue:hover {
  background: #00205c;
  color: #fff;
  transition: all 0.5s;
}

.btn-primary {
  background: #043289 !important;
  color: white !important;
  border: none !important;
}
a {
  color: inherit;
  text-decoration: none;
}

/* Global CSS */

body .container {
  width: 100%;
  max-width: 1430px;
}

/*********************  Default-CSS  *********************/

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-moz-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-ms-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-o-file-upload-button {
  cursor: pointer;
}

input[type="file"],
a[href],
input[type="submit"],
input[type="button"],
input[type="image"],
label[for],
select,
button,
.pointer {
  cursor: pointer;
}

::-moz-focus-inner {
  border: 0px solid transparent;
}

::-webkit-focus-inner {
  border: 0px solid transparent;
}

*::-moz-selection {
  color: #fff;
  background: #000;
}

*::-webkit-selection {
  color: #fff;
  background: #000;
}

*::-webkit-input-placeholder {
  color: #333333;
  opacity: 1;
}

*:-moz-placeholder {
  color: #333333;
  opacity: 1;
}

*::-moz-placeholder {
  color: #333333;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #333333;
  opacity: 1;
}

.MuiFormLabel-root {
  color: #000 !important;
}

.MuiFormLabel-root.Mui-focused {
  background-color: #043289 !important;
  border-radius: 5px;
  color: #fff !important;
}
a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

a,
span,
div a:hover,
div a:active,
button {
  text-decoration: none;
}

*::after,
*::before,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.no-list li,
.no-list ul,
.no-list ol,
footer li,
footer ul,
footer ol,
header li,
header ul,
header ol {
  list-style: inside none none;
}

.no-list ul,
.no-list ol,
footer ul,
footer ol,
header ul,
header ol {
  margin: 0;
  padding: 0;
}

a {
  outline: none;
  color: #555;
}

a:hover {
  color: #000;
}

body .clearfix,
body .clear {
  clear: both;
  line-height: 100%;
}

body .clearfix {
  height: auto;
}

* {
  outline: none !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul:after,
li:after,
.clr:after,
.clearfix:after,
.container:after,
.grve-container:after {
  clear: both;
  display: block;
  content: "";
}

div input,
div select,
div textarea,
div button {
  font-family: "Noto Sans", sans-serif;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "Noto Sans", sans-serif;
  line-height: 120%;
  color: #333;
  font-weight: bold;
  margin: 0 0 15px;
}

body h1:last-child,
body h2:last-child,
body h3:last-child,
body h4:last-child,
body h5:last-child,
body h6:last-child {
  margin-bottom: 0;
}

.banner-2 {
  display: flex;
  flex-direction: row;
}

.quantity-selector a {
  width: 30px;
}

/* Quntatiy css */

.quantity-selector {
  display: flex;
  align-items: center;
  margin-top: -20px;
}

.quantity-selector button {
  width: 30px;
  height: 30px;
  font-size: 1.2rem;
  /* font-weight: bold; */
  border-radius: 0;
  padding: 0;
}

.quantity-selector a {
  width: 30px;
}

.quantity {
  display: inline-block;
  width: 1.5rem;
  text-align: center;
  margin: 0 10px;
  font-weight: 700;
}

.btn-white {
  background: #fff;
  color: #043289;
  border-radius: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  padding: 8px 18px;
}

.btn-white:hover {
  background: #043289;
  color: #fff;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background: white;
}

.btn-blue {
  color: #fff;
  font-size: 16px;
  padding: 6px 12px;
  line-height: 30px;
  background: #043289;
  border-radius: 0;
  font-weight: lighter;
  font-weight: 600;
  transition: all 0.5s;
}

.btn-blue:hover {
  background: #00205c;
  color: #fff;
  transition: all 0.5s;
}
.btn:disabled {
  background: gray !important;
}
.btn-arrow {
  display: flex;
  align-items: center;
  width: fit-content;
}
.btn-arrow svg {
  font-size: 24px;
}

.DesktopInnerText-2 {
  display: flex;
  flex-direction: column;
}

/* inventory breadcrumbs and title */

.inventory-breadcrumbs {
  display: flex;
  flex-direction: column;
  background-color: #f7f8fb;
  height: "10px";
  color: #00205c;
}

.detailPage_breadcrumbs {
  padding-top: 220px;
}

@media (max-width: 768px) {
  .detailPage_breadcrumbs {
    padding-top: 140px;
    margin-bottom: 18px;
  }

  .productTitle h1 {
    font-size: 21px;
  }
  .btn-blue {
    padding: 5px 10px;
  }
  .btn-arrow svg {
    font-size: 20px;
  }
}

.top-breadcrumbs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f7f8fb;
  color: #00205c;
}

.inventory-breadcrumbs p {
  font-size: 24px;
  font-weight: 700;
  color: #00205c;
}

@media (max-width: 575px) {
  .btn-blue {
    padding: 5px 10px;
  }
  .btn-arrow svg {
    font-size: 20px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 4rem;
}

input:focus {
  zoom: 1;
}

input:focus {
  text-size-adjust: 100%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #043289;
  border-radius: 4rem;
}
a {
  cursor: pointer;
}

.title_link {
  cursor: pointer;
  text-wrap: wrap;
}

.title_link:hover {
  text-decoration: underline;
}

.custom-select {
  z-index: 2 !important;
  margin: 0 !important;
  /* padding: 0 !important; */
  min-height: 56px !important;
}

.css-13cymwt-control {
  min-height: 56px !important;
}

.whiteTag :hover {
  color: #fff !important;
  background-color: #043289 !important;
}

.margin-top-10 {
  margin-top: 0.6rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-primary {
  background: #043289 !important;
  color: white !important;
  border: none !important;
}
.modal-content {
  background: #f8f8f8;
}

.scroll70 {
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.bg-gray {
  background-color: #f8f8f8 !important;
}

.__PrivateStripeElement iframe,
.InputElement {
  height: 40px !important;
}

.fade.modal-backdrop {
  z-index: 9999;
}
.modal {
  z-index: 99999;
  --bs-modal-width: 1100px !important;
}
.table thead tr th {
  text-transform: capitalize;
}
.modal-backdrop {
  z-index: 9999;
}
.css-1n6sfyn-MenuList {
  max-height: 220px !important;
}

.css-tr4s17-option {
  background: #043288 !important;
}
/* .spinner{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #ffff;
    z-index: 999999;
    left: 0;
}
.spinner img{
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    max-width: 150px;
    position: absolute;
} */

.spinner {
  background: #fff;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 99999999;
}

.SpinnerIcon {
  max-width: 150px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.SpinnerIcon img {
  width: 100%;
  animation: rotating 2s linear infinite;
  margin-bottom: 10px;
  max-width: 100px;
}

.SpinnerIcon p {
  font-size: 22px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
}
.loading {
  overflow: hidden;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.fade-in {
  opacity: 0;
  animation: fadeIn ease 1s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 575px) {
  /* .modal-dialog-centered {
        max-height: 375px;
        min-height: auto;
        overflow: auto;
        top: 50%;
        transform: translateY(-50%);
    } */
  .modal-body .scroll70.px-2 {
    max-height: 50vh;
  }
  section.sellwithusSection.pb100 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  ol.breadcrumb {
    flex-wrap: nowrap;
    font-size: 14px;
    width: 100%;
  }

  ol.breadcrumb li a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
    display: block;
  }
  ol.breadcrumb li {
    display: flex;
  }
}
body input[type="file"] {
  padding: 6px 10px !important;
}

@media (max-width: 1429px) {
  .sellToUsBoxMain {
    padding-bottom: 0;
  }
}
@media (max-width: 1199px) {
  .ClientBanner {
    padding: 70px 0 !important;
  }

  .rentFormMain h3 {
    font-size: 26px;
  }

  .rentFormMain p {
    font-size: 16px;
    line-height: 26px;
  }
  .rentFormMain {
    padding: 60px 15px;
  }
  .sellwithusSection {
    margin-bottom: 0 !important;
  }
  .DesktopInnerText {
    display: none !important;
  }

  .MixBlendImg {
    mix-blend-mode: darken;
  }
}
@media (max-width: 991px) {
  .about-title {
    padding: 20px 15px !important;
    line-height: 1.4 !important;
  }
  .rentFormMain h3 {
    font-size: 24px;
  }

  .sellwithusSection .d-flex.g-5 {
    flex-wrap: wrap;
  }

  .RecyclingInfo {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .sellwithusImg.RecyclingImgs {
    margin: 0 0 10px 0;
    padding: 0;
    width: 15%;
  }
  .EquipmentServicingBanner {
    padding: 40px 0 30px;
  }

  .EquipmentServicingBanner h3,
  .EquipmentServicingBanner p {
    max-width: 100%;
  }

  .EquipmentServicingBanner h3 {
    font-size: 26px;
  }

  .finders-b {
    display: none;
  }

  .EquipmentServicingBanner p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 5px;
  }

  .EquipmentServicingBanner span {
    margin-bottom: 10px;
  }

  .PageText h3 {
    text-align: left;
    margin-bottom: 10px;
  }

  .PageCard {
    height: auto;
    padding: 15px;
  }

  .PageText p {
    font-size: 14px;
  }

  .accordion-button {
    font-size: 18px;
    line-height: 1.4;
    padding: 0 0 10px;
  }

  .card {
    height: 310px;
    margin-bottom: 20px;
    cursor: pointer;
    align-items: center !important;
  }

  .pb-0.accordion-body {
    padding-left: 0;
  }

  .rentFormMain {
    justify-content: flex-start;
    gap: 10px;
  }

  .rentFormMain div {
    width: 100%;
  }

  .image-gallery-slide {
    /* height: 45vh; */
    margin-bottom: 20px;
  }

  .rentFormMain div p {
    margin-bottom: 20px !important;
  }
  .sellToUsBoxMain {
    padding-top: 0;
  }

  .SellBannerFix .sellwithusInfo {
    width: 100%;
  }

  .SellBannerFix .sellwithusImg {
    width: 12%;
    height: auto;
    margin-bottom: 20px;
  }

  .sellwithusInfo h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  section.sellwithusSection.SellBannerFix {
    padding-bottom: 0;
  }
  section.sellwithusSection.SellBannerFix.contractBannerFix .sellwithusImg {
    display: none;
  }
}
@media (max-width: 767px) {
  .ClientBanner {
    padding: 130px 0 0 !important;
    background-position: top left !important;
    background-size: 120px !important;
    margin-top: 20px !important;
  }

  .contactBanner h3 {
    font-size: 24px !important;
    margin-bottom: 0 !important;
  }

  .mobile-sp-div {
    display: none;
  }

  .contractBannerFix .sellwithusInfo {
    width: 100% !important;
  }

  .ClientBanner p,
  .RecyclingBanner p {
    font-size: 16px !important;
  }
  section.sellwithusSection.pb100.pt-4 {
    padding-bottom: 0;
  }

  .sellwithusInfo h4 {
    font-size: 24px;
  }

  .sellwithusInfo p {
    font-size: 14px;
    margin-bottom: 10px !important;
  }

  .MobileTextInfo {
    display: block !important;
  }

  .distinctText h5 {
    font-size: 18px;
  }
  .distinctText h6 {
    font-size: 16px;
  }
  .distinctText h6 p {
    font-size: 14px;
  }
  .modal-title.h4 {
    font-size: 20px;
  }
  .Terms_Info .accordion-button {
    padding: 10px;
    font-size: 16px;
  }

  .Terms_Info .accordion-body {
    padding: 15px;
  }

  .Terms_Info .box_service li {
    margin: 0 !important;
  }

  .Terms_Info h4 {
    font-size: 22px;
    margin-bottom: 12px;
  }

  .TermsBox h5 {
    font-size: 18px;
  }

  .TermsBox p {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .about-column .nav-link {
    padding: 15px;
  }
  section.sellwithusSection.pb100 {
    padding-top: 20px !important;
  }
  .ClientBanner p,
  .RecyclingBanner p {
    width: auto;
  }
  .ClientBanner p::before,
  .RecyclingBanner p::before {
    right: 0;
    bottom: -15px;
  }
  .rentFormMain {
    padding: 30px 15px;
  }
  .rentFormMain h3 {
    font-size: 24px;
  }
}
sup {
  font-size: 12px;
}

.w-80 {
  width: 80%;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.product-wrap {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: flex-start;
  position: relative;
}

/* .product-sidebar {
  background: #fff;
  position: sticky;
  top: 200px;
  padding: 20px;
  height: auto;
}  */

.product-sidebar h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 15px;
}

.product-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  height: auto;
  width: 280px;
  position: sticky;
  top: 200px;
  padding: 20px;

  /* height: calc(100vh - 310px); */
}
.filter-list-item {
  height: auto !important;
}
.cateList {
  /* overflow: auto; */
  /* position: sticky; */
  /* height: calc(100vh - 310px); */
}

.product-sidebar ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #000000;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
  display: block;
  padding: 8px 0 12px 0;
}

.product-sidebar ul li a:hover {
  color: #043289;
}

.product-sidebar ul li:nth-last-child(1) a {
  border-bottom: 0;
  padding-bottom: 0;
}

.product-main-list {
  /* width: calc(100% - 315px); */
  width: 100%;
}

.product-main-list h2 {
  letter-spacing: 0.01em;
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 43px;
  background: #ffffff;
  margin-bottom: 10px;
  text-align: center;
  padding: 30px 15px;
}

.ProductCardItem {
  background: #ffffff;
  padding: 12px;
  margin: 12.5px 0;
  transition: all 0.5s;
}
.ProductCardItem:hover {
  box-shadow: 0 0 10px 0px #00000020;
}
.ProductImg {
  position: relative;
  width: 100%;
  height: 240px;
  margin-bottom: 12px;
  overflow: hidden;
  transition: all 0.5s;
}
.ProductImg img {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  object-fit: cover;
}

.whiteTag {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 9;
  background-color: #fff;
  color: #043289;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  padding: 2px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  width: 90px;
  max-width: 90px;
  cursor: pointer;
}

.CloseSidebar {
  display: none;
}
.heart-Ico {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #e80000;
  font-size: 22px;
}
.heart-Ico svg path {
  fill: #e80000;
}

.ProductInfo h1 {
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 6px;
}

.ProductInfo p {
  color: rgba(0, 0, 0, 0.6);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
}

.ProductInfo h6 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
}

.ProductInfo h6 del {
  color: rgba(0, 0, 0, 0.4);
  font-style: italic;
  font-weight: 600;
}

.ProductBtnWrap {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.ProductBtnWrap a {
  width: calc(50% - 6px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-header-wrap a.CloseSidebar {
  display: none !important;
  color: #043289;
  background: rgba(4, 50, 137, 0.1);
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  z-index: 999;
}
.product-sidebar h5 a {
  display: none;
}
@media (max-width: 1599px) {
  .product-main-list h2 {
    font-size: 24px;
  }

  .product-sidebar ul li a {
    font-size: 14px;
  }

  .ProductInfo h5 {
    font-size: 22px;
  }

  .homePageRow .col-xl-3 {
    flex: 0 0 auto;
    width: 33.33%;
  }
  .product-sidebar ul {
    /* height: calc(100vh - 280px); */
  }
}

@media (max-width: 1429px) {
  .ProductBtnWrap a {
    padding: 6px 10px;
  }

  .ProductImg {
    height: 250px;
  }
  .ProductInfo h5 {
    font-size: 18px;
  }
}

@media (max-width: 1199px) {
  .ProductImg {
    height: 300px;
  }
  .product-main-list .row .col-lg-4 {
    width: 50%;
  }
  .ProductBtnWrap a {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .product-header-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .product-header-wrap a.CloseSidebar {
    display: flex !important;
  }
  .product-header-wrap h2 {
    background-color: transparent;
    margin-right: auto;
    margin-bottom: 0;
    padding: 0;
    font-size: 22px;
  }

  .product-header-wrap a {
    display: flex;
  }
  /* 
  .product-sidebar {
    position: absolute;
    top: 0;
    transition: all 0.5s;
    z-index: 999;
    transform: translateX(-400px);
  }
} */
  .product-sidebar.show {
    transform: translateX(0);
    transition: all 0.5s;
  }
  .product-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    height: 100vh;
    max-height: 100%;
    max-width: 80%;
    width: 80%;
    transform: translateX(-100%);
    transition: all 0.5s;
    overflow: auto;
  }
  .CloseSidebar {
    display: block;
  }
  .product-sidebar h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product-sidebar h5 a {
    color: red;
  }
  body.showSidebar .product-sidebar {
    transform: translateX(0);
    transition: all 0.5s;
    z-index: 999999;
  }
  .overflowfilters {
    max-height: initial;
    overflow: auto;
  }
  body:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  body.showSidebar {
    overflow: hidden;
  }
  body.showSidebar:after {
    opacity: 0.6;
    z-index: 99999;
  }
  .product-sidebar h5 a {
    display: block;
  }
  .product-main-list {
    width: 100%;
  }

  .product-wrap {
    position: relative;
    flex-wrap: wrap;
  }

  /* .product-main-list .row {
    flex-wrap: nowrap;
    overflow: auto;
  } */
  .product-wrap {
    flex-wrap: wrap;
  }

  .product-sidebar ul {
    height: auto;
  }

  .inventoryPageSidebar {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .product-header-wrap {
    margin-bottom: 10px;
  }

  .ProductInfo h5 {
    font-size: 18px;
    font-weight: 400;
    line-height: initial;
  }

  .ProductInfo p {
    font-size: 14px;
  }

  .ProductImg {
    height: 260px;
  }
  .heart-Ico {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 575px) {
  .product-main-list .row .col-lg-4 {
    width: 100%;
  }
}

/* width */
::-webkit-scrollbar {
  width: 9px;
  height: 6px;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 4rem;
}

input:focus {
  zoom: 1;
}

input:focus {
  text-size-adjust: 100%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #043289;
  border-radius: 4rem;
}
a {
  cursor: pointer;
}

.title_link {
  cursor: pointer;
  text-wrap: wrap;
}

.title_link:hover {
  text-decoration: underline;
}

.custom-select {
  z-index: 2 !important;
  margin: 0 !important;
  /* padding: 0 !important; */
  min-height: 56px !important;
}

.css-13cymwt-control {
  min-height: 56px !important;
}

.whiteTag :hover {
  color: #fff !important;
  background-color: #043289 !important;
}

.margin-top-10 {
  margin-top: 0.6rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-primary {
  background: #043289 !important;
  color: white !important;
  border: none !important;
}
.modal-content {
  background: #f8f8f8;
}

.scroll70 {
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.bg-gray {
  background-color: #f8f8f8 !important;
}

.__PrivateStripeElement iframe,
.InputElement {
  height: 40px !important;
}

.fade.modal-backdrop {
  z-index: 9999;
}
.modal {
  z-index: 99999;
}
.table thead tr th {
  text-transform: capitalize;
}
.modal-backdrop {
  z-index: 9999;
}
.css-1n6sfyn-MenuList {
  max-height: 220px !important;
}

.css-tr4s17-option {
  background: #043288 !important;
}
/* .spinner{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #ffff;
    z-index: 999999;
    left: 0;
}
.spinner img{
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    max-width: 150px;
    position: absolute;
} */

.MobileTextInfo {
  display: none;
}

.spinner {
  background: #fff;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 99999999;
}

.SpinnerIcon {
  max-width: 150px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.SpinnerIcon img {
  width: 100%;
  animation: rotating 2s linear infinite;
  margin-bottom: 10px;
  max-width: 100px;
}

.rentFormMain {
  border-radius: 8px;
}

.rentFormMain::after {
  border-radius: 8px !important;
}

.SpinnerIcon p {
  font-size: 22px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
}
.loading {
  overflow: hidden;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.fade-in {
  opacity: 0;
  animation: fadeIn ease 1s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 575px) {
  /* .modal-dialog-centered {
        max-height: 375px;
        min-height: auto;
        overflow: auto;
        top: 50%;
        transform: translateY(-50%);
    } */
  .modal-body .scroll70.px-2 {
    max-height: 50vh;
  }
  section.sellwithusSection.pb100 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  ol.breadcrumb {
    flex-wrap: nowrap;
    font-size: 14px;
    width: 100%;
  }

  ol.breadcrumb li a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
    display: block;
  }
  ol.breadcrumb li {
    display: flex;
  }
}
body input[type="file"] {
  padding: 6px 10px !important;
}

@media (max-width: 1429px) {
  .sellToUsBoxMain {
    padding-bottom: 0;
  }
}
@media (max-width: 1199px) {
  .ClientBanner {
    padding: 70px 0 !important;
  }

  .rentFormMain h3 {
    font-size: 26px;
  }

  .rentFormMain p {
    font-size: 16px;
    line-height: 26px;
  }
  .rentFormMain {
    padding: 60px 15px;
  }
  .sellwithusSection {
    margin-bottom: 0 !important;
  }
  .DesktopInnerText {
    display: flex !important;
  }

  /* .MobileDesInfo {
    display: none !important;
  } */

  .MixBlendImg {
    mix-blend-mode: darken;
  }
}
@media (max-width: 991px) {
  .about-title {
    padding: 20px 15px !important;
    line-height: 1.4 !important;
  }
  .rentFormMain h3 {
    font-size: 24px;
  }

  .sellwithusSection .d-flex.g-5 {
    flex-wrap: wrap;
  }

  .RecyclingInfo {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .sellwithusImg.RecyclingImgs {
    margin: 0 0 10px 0;
    padding: 0;
    width: 15%;
  }
  .EquipmentServicingBanner {
    padding: 40px 0 30px;
  }

  .EquipmentServicingBanner h3,
  .EquipmentServicingBanner p {
    max-width: 100%;
  }

  .EquipmentServicingBanner h3 {
    font-size: 26px;
  }

  .EquipmentServicingBanner p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 5px;
  }

  .EquipmentServicingBanner span {
    margin-bottom: 10px;
  }

  .PageText h3 {
    text-align: left;
    margin-bottom: 10px;
  }

  .sell-top {
    margin-top: 100px;
  }

  .MobileTextInfo {
    /* display: none !important; */
    display: block !important;
  }

  .MobileDesInfo {
    display: none;
  }

  .MobileTextInfo {
    display: none;
  }

  .PageCard {
    height: auto;
    padding: 15px;
  }

  .PageText p {
    font-size: 14px;
  }

  .accordion-button {
    font-size: 18px;
    line-height: 1.4;
    padding: 0 0 10px;
  }

  .pb-0.accordion-body {
    padding-left: 0;
  }

  .rentFormMain {
    justify-content: flex-start;
    gap: 10px;
  }

  .rentFormMain div {
    width: 100%;
  }

  .rentFormMain div p {
    margin-bottom: 20px !important;
  }
  .sellToUsBoxMain {
    padding-top: 0;
  }

  .SellBannerFix .sellwithusInfo {
    width: 100%;
  }

  .SellBannerFix .sellwithusImg {
    width: 12%;
    height: auto;
    margin-bottom: 20px;
  }

  .sellwithusInfo h4 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  section.sellwithusSection.SellBannerFix {
    padding-bottom: 0;
  }
  section.sellwithusSection.SellBannerFix.contractBannerFix .sellwithusImg {
    display: none;
  }
}
@media (max-width: 767px) {
  .ClientBanner {
    padding: 130px 0 0 !important;
    background-position: top left !important;
    background-size: 120px !important;
    margin-top: 20px !important;
  }

  .contactBanner h3 {
    font-size: 24px !important;
    margin-bottom: 0 !important;
  }

  .ClientBanner p,
  .RecyclingBanner p {
    font-size: 16px !important;
  }
  section.sellwithusSection.pb100.pt-4 {
    padding-bottom: 0;
  }

  .sellwithusInfo h4 {
    font-size: 24px;
  }

  .sellwithusInfo p {
    font-size: 14px;
    margin-bottom: 10px !important;
  }

  .distinctText h5 {
    font-size: 18px;
  }
  .distinctText h6 {
    font-size: 16px;
  }
  .distinctText h6 p {
    font-size: 14px;
  }
  .modal-title.h4 {
    font-size: 20px;
  }
  .Terms_Info .accordion-button {
    padding: 10px;
    font-size: 16px;
  }

  .Terms_Info .accordion-body {
    padding: 15px;
  }

  .Terms_Info .box_service li {
    margin: 0 !important;
  }

  .Terms_Info h4 {
    font-size: 22px;
    margin-bottom: 12px;
  }

  .TermsBox h5 {
    font-size: 18px;
  }

  .TermsBox p {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .about-column .nav-link {
    padding: 15px;
  }
  section.sellwithusSection.pb100 {
    padding-top: 20px !important;
  }
  .ClientBanner p,
  .RecyclingBanner p {
    width: auto;
  }
  .ClientBanner p::before,
  .RecyclingBanner p::before {
    right: 0;
    bottom: -15px;
  }
  .rentFormMain {
    padding: 30px 15px;
  }
  .rentFormMain h3 {
    font-size: 24px;
  }
}
sup {
  font-size: 12px;
}

.w-80 {
  width: 80%;
}

.nav-link {
  color: #043289 !important;
  font-weight: 500;
  border-radius: 0px !important;
}

@media screen and (max-width: 787px) {
  .btn {
    width: 100% !important;
  }
}

.sellToUsBoxMain {
  padding: 60px 0;
  text-align: center;
}

.sellToUsBoxMain .row {
  justify-content: center;
  margin-bottom: 20px;
}
.SellBannerFix {
  padding-top: 40px;
}

.SellBannerFix .sellwithusImg {
  height: 300px;
  clip-path: unset;
}
.SellBannerFix .sellwithusImg img {
  object-fit: contain;
  mix-blend-mode: darken;
}

.SellBannerFix .sellwithusInfo {
  width: 80%;
}

.SellBannerFix .sellwithusImg {
  width: 20%;
}
.SellBannerFix .sellwithusImg:after {
  clip-path: unset;
  display: none;
}
.sellToUsBoxMain .bg-white {
  display: block;
  margin-bottom: 25px;
  padding: 20px !important;
  text-align: center;
  height: calc(100% - 25px);
}

.sellToUsBoxMain h4 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 26px;
  font-weight: 700;
}

.sellToUsBoxMain h6 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #043289;
}

.sellToUsBoxMain p {
  font-size: 15px;
  line-height: 1.6;
}

.sellToUsBoxMain p a {
  color: #043289;
  text-decoration: underline;
}

.sellwithusSection .white-0 {
  background-color: transparent !important;
}

.white-0 .sellwithusImg::after {
  background: linear-gradient(
    117deg,
    #f8f8f8 23.82%,
    rgb(248 248 248 / 20%) 59.79%
  );
}
.bg-boxwhite p {
  text-align: left;
  max-width: 1020px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .white-0 .sellwithusImg::after {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
    background: linear-gradient(
      16deg,
      #ffffff 20.82%,
      rgba(255, 255, 255, 0) 57.79%
    );
  }
}

.sellwithusImg {
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
  position: relative;
  width: 60%;
  height: 430px;
}

.sellwithusImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

html body {
  font-family: "Noto Sans", sans-serif;
  margin: 0;
  line-height: 1.3;
  background: #f8f8f8;
}

body .container {
  width: 100%;
  max-width: 1430px;
}

/*********************  Default-CSS  *********************/

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-moz-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-ms-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-o-file-upload-button {
  cursor: pointer;
}

input[type="file"],
a[href],
input[type="submit"],
input[type="button"],
input[type="image"],
label[for],
select,
button,
.pointer {
  cursor: pointer;
}

::-moz-focus-inner {
  border: 0px solid transparent;
}

::-webkit-focus-inner {
  border: 0px solid transparent;
}

*::-moz-selection {
  color: #fff;
  background: #000;
}

*::-webkit-selection {
  color: #fff;
  background: #000;
}

*::-webkit-input-placeholder {
  color: #333333;
  opacity: 1;
}

*:-moz-placeholder {
  color: #333333;
  opacity: 1;
}

*::-moz-placeholder {
  color: #333333;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #333333;
  opacity: 1;
}

a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

a,
span,
div a:hover,
div a:active,
button {
  text-decoration: none;
}

*::after,
*::before,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.no-list li,
.no-list ul,
.no-list ol,
footer li,
footer ul,
footer ol,
header li,
header ul,
header ol {
  list-style: inside none none;
}

.no-list ul,
.no-list ol,
footer ul,
footer ol,
header ul,
header ol {
  margin: 0;
  padding: 0;
}

a {
  outline: none;
  color: #555;
}

a:hover {
  color: #000;
}

body .clearfix,
body .clear {
  clear: both;
  line-height: 100%;
}

body .clearfix {
  height: auto;
}

* {
  outline: none !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul:after,
li:after,
.clr:after,
.clearfix:after,
.container:after,
.grve-container:after {
  clear: both;
  display: block;
  content: "";
}

div input,
div select,
div textarea,
div button {
  font-family: "Noto Sans", sans-serif;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "Noto Sans", sans-serif;
  line-height: 120%;
  color: #333;
  font-weight: bold;
  margin: 0 0 15px;
}

body h1:last-child,
body h2:last-child,
body h3:last-child,
body h4:last-child,
body h5:last-child,
body h6:last-child {
  margin-bottom: 0;
}

.btn-white {
  background: #fff;
  color: #043289;
  border-radius: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  padding: 8px 18px;
}

.btn-white:hover {
  background: #043289;
  color: #fff;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background: white;
}

.btn-blue {
  color: #fff;
  font-size: 16px;
  padding: 6px 12px;
  line-height: 30px;
  background: #043289;
  border-radius: 0;
  font-weight: lighter;
  font-weight: 600;
  transition: all 0.5s;
}

.btn-blue:hover {
  background: #00205c;
  color: #fff;
  transition: all 0.5s;
}
.btn:disabled {
  background: gray !important;
}
.btn-arrow {
  display: flex;
  align-items: center;
  width: fit-content;
}
.btn-arrow svg {
  font-size: 24px;
}

@media (max-width: 575px) {
  .btn-blue {
    padding: 5px 10px;
  }
  .btn-arrow svg {
    font-size: 20px;
  }
}
